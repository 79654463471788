import React, { useState, useEffect } from 'react'
import SolanaButton from '@/components/wallet/SolanaButton'
import { EthereumButton } from '@/components/wallet/EthereumButton'
import erc20Abi from '@/utils/abi/erc20.json'
import { USDC } from '@/configs/tokens'
import { useAccount, useReadContracts } from 'wagmi'
import { formatUnits } from 'viem'
import { useUserBalances } from '@/hooks/useUserBalances'
import { useAsset } from '@/hooks/useAsset'
import BridgeModal from './bridgeModal'

const usdyId = 'USDY'

export const WalletButton = () => {
  const [open, setOpen] = useState(false)
  const [isDepositOpen, setIsDepositOpen] = useState(false)
  const [depositAmount, setDepositAmount] = useState('')

  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false)
  const [withdrawAmount, setWithdrawAmount] = useState('')

  const { isConnected, address } = useAccount()
  const { balances } = useUserBalances()
  const { asset: usdyAssetResult } = useAsset(usdyId)

  const usdyBalanceResult = (balances?.available || []).find((b) => b.assetID === usdyId)

  const usdcBalanceResult: any = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: USDC.address as any,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [address],
      },
    ],
  })

  const handleClose = () => {
    setOpen(false)
    console.log('handleClose')
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (open) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  useEffect(() => {
    if (!isDepositOpen) {
      setDepositAmount('')
    }
  }, [isDepositOpen])

  return (
    <div>
      {open && (
        <div className={'relative'} onClick={handleClose}>
          <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
            <div
              id={'wallet-modal'}
              className={
                'w-96 p-4 bg-panel flex flex-col gap-2 justify-center items-center border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <div className={'text-white text-xl font-semibold mb-6'}>Wallet</div>
              <div className={'flex flex-col text-center'}>
                <EthereumButton
                  setIsDepositOpen={setIsDepositOpen}
                  setIsWithdrawOpen={setIsWithdrawOpen}
                  isConnected={isConnected}
                  address={address}
                  usdcBalanceResult={usdcBalanceResult}
                  usdyBalanceResult={usdyBalanceResult}
                  usdyAssetResult={usdyAssetResult}
                />
              </div>
              <div className={'flex flex-col text-center mt-4'}>
                <SolanaButton />
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        onClick={() => setOpen(true)}
        className='btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
      >
        Wallet
      </button>
      {isDepositOpen && (
        <BridgeModal
          title='Deposit'
          onClose={() => setIsDepositOpen(false)}
          from={{
            chain: 'Ethereum',
            chainLogo: '/assets/images/wallet/ethereum.svg',
            asset: 'USD Coin',
            assetLogo: '/assets/images/tokens/usdc.svg',
          }}
          maxBalance={formatUnits(usdcBalanceResult?.data?.[0] || '', USDC.decimals)}
          amount={depositAmount}
          setAmount={setDepositAmount}
          buttonText={'Deposit'}
        />
      )}
      {isWithdrawOpen && (
        <BridgeModal
          title='Withdraw'
          onClose={() => setIsWithdrawOpen(false)}
          from={{
            chain: 'Dora Chain',
            chainLogo: '/favicon.png',
            asset: 'USDY',
            assetLogo: `/assets/images/tokens/usdy.png`,
          }}
          maxBalance={formatUnits((usdyBalanceResult?.amount || '') as any, usdyAssetResult?.decimals || 2)}
          amount={withdrawAmount}
          setAmount={setWithdrawAmount}
          buttonText={'Withdraw'}
        />
      )}
    </div>
  )
}
