import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useOrderBots } from '@/hooks/useOrderBots'
import { tableColumnsOrderBots } from '@/components/administration/TableColumnsOrderBots'
import { CreateOrderBotModal } from '@/components/administration/CreateOrderBotModal'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  getSdk,
  StartOrderBotMutationVariables,
  StopOrderBotMutationVariables,
} from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'

export function OrderBots() {
  const { orderBots } = useOrderBots()
  const [selectedOrderBot, setSelectedOrderBot] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const columns = tableColumnsOrderBots

  const startOrderBotMutation = useMutation({
    mutationFn: (params: StartOrderBotMutationVariables) =>
      getSdk(graphQLClient)
        .StartOrderBot(params)
        .then(() => {
          createNotification(
            'Start Order Bot',
            'Order Bot started successfully',
            NotificationType.SIMPLE,
            NotificationStatus.SUCCESS,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Could not start Order Bot'
          createNotification('Error on Start Order Bot', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ORDER_BOTS'] }).finally()
    },
  })

  const stopOrderBotMutation = useMutation({
    mutationFn: (params: StopOrderBotMutationVariables) =>
      getSdk(graphQLClient)
        .StopOrderBot(params)
        .then(() => {
          createNotification(
            'Stop Order Bot',
            'Order Bot stopped successfully',
            NotificationType.SIMPLE,
            NotificationStatus.SUCCESS,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Could not stop Order Bot'
          createNotification('Error on Stop Order Bot', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ORDER_BOTS'] }).finally()
    },
  })

  const table = useReactTable({
    data: orderBots,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      editOrderBot: (botID: string) => {
        setSelectedOrderBot(botID)
        setIsOpen(true)
      },
      startStopOrderBot: (botID: string, start: boolean) => {
        if (start) {
          startOrderBotMutation.mutate({ botId: botID })
        } else {
          stopOrderBotMutation.mutate({ botId: botID })
        }
      },
    },
  })

  useEffect(() => {
    if (!isOpen) {
      setSelectedOrderBot(null)
    }
  }, [isOpen])

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <div className={'flex flex-row gap-2 justify-between mb-2'}>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>User Roles</h1>
        <CreateOrderBotModal orderBotID={selectedOrderBot} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>

      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className='dark:bg-dark-darker border-b border-b-dark'
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className={`border-b border-dark`} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
