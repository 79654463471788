import { clearAllCandles } from '@/store/candleSlice'
import { clearAllLastChartPrice } from '@/store/chartPriceSlice'
import { clearNotifications } from '@/store/notificationSlice'
import { getAuth, signOut } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { useDisconnect } from 'wagmi'

const useSignOutAndClearStore = () => {
  const { disconnectAsync } = useDisconnect()
  const dispatch = useDispatch()

  const signOutAndClearStore = async () => {
    await disconnectAsync()
    await signOut(getAuth())
    localStorage.clear()
    dispatch(clearNotifications())
    dispatch(clearAllLastChartPrice())
    dispatch(clearAllCandles())
  }

  return signOutAndClearStore
}

export default useSignOutAndClearStore
