import { useQuery } from '@tanstack/react-query'
import { getSdk, OrderBot, User } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const useOrderBots = () => {
  const {
    isLoading: loadingOrderBots,
    data: orderBots,
    status,
  } = useQuery({
    queryKey: ['ORDER_BOTS'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .OrderBots()
        .then((result) => result.orderBots as OrderBot[])
        .catch((err) => {
          const message = err?.message ?? 'Error on OrderBots'
          createNotification('Error on OrderBots', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: [],
  })
  useQueryTime(`['ORDER_BOTS'}]`, status)
  return {
    loadingOrderBots,
    orderBots: orderBots ?? [],
  }
}
