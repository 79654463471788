import { ColumnDef } from '@tanstack/react-table'
import { OrderBot, User } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationType } from '@/utils/notificationUtils'
import { FiEdit } from 'react-icons/fi'
import dayjs from 'dayjs'
import { IoPlaySharp, IoStopSharp } from 'react-icons/io5'

export const tableColumnsOrderBots: ColumnDef<OrderBot>[] = [
  {
    accessorKey: 'date',
    header: () => <div className='text-xs text-left text-white'>Update at</div>,
    cell: ({ row }) => {
      //show date in format yyyy-mm-dd HH:MM:SS
      const date = dayjs(row.original.updatedAt * 1000).format('YYYY-MM-DD HH:mm:ss')
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(date)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Date ${date} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {date}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'botID',
    header: () => <div className='text-xs text-left text-white'>BotID</div>,
    cell: ({ row }) => {
      const botID = row.original.botID
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(botID)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `BotID ${botID} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {botID}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'name',
    header: () => <div className='text-xs text-left text-white'>Name</div>,
    cell: ({ row }) => {
      const name = row.original.name
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(name)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Bot Name ${name} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {name}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'orderBookID',
    header: () => <div className='text-xs text-left text-white'>OrderBookID</div>,
    cell: ({ row }) => {
      const orderBookID = row.original.orderBookID
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(orderBookID)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `OrderBookID ${orderBookID} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {orderBookID}
        </div>
      )
    },
    size: 150,
  },
  {
    accessorKey: 'frequency',
    header: () => <div className='text-xs text-left text-white'>Frequency</div>,
    cell: ({ row }) => {
      const frequency = row.original.frequency
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(frequency.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Frequency ${frequency} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {frequency}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'levels',
    header: () => <div className='text-xs text-left text-white'>Levels</div>,
    cell: ({ row }) => {
      const levels = row.original.levels
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(levels.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Levels ${levels} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {levels}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'customTick',
    header: () => <div className='text-xs text-left text-white'>Custom Tick</div>,
    cell: ({ row }) => {
      const customTick = row.original.customTick
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(customTick.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Custom Tick ${customTick} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {customTick}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'maxMult',
    header: () => <div className='text-xs text-left text-white'>Max Mult</div>,
    cell: ({ row }) => {
      const maxMult = row.original.maxMult
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(maxMult.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Max Mult ${maxMult} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {maxMult}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'minMult',
    header: () => <div className='text-xs text-left text-white'>Min Mult</div>,
    cell: ({ row }) => {
      const minMult = row.original.minMult
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(minMult.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Min Mult ${minMult} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {minMult}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'strategy',
    header: () => <div className='text-xs text-left text-white'>Strategy</div>,
    cell: ({ row }) => {
      const strategy = row.original.strategy
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(strategy.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Strategy ${strategy} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {strategy}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'priceList',
    header: () => <div className='text-xs text-left text-white'>Price List</div>,
    cell: ({ row }) => {
      const priceList = row.original.priceList.join(', ')
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(priceList.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Price List ${priceList} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {priceList}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'amountList',
    header: () => <div className='text-xs text-left text-white'>Amount List</div>,
    cell: ({ row }) => {
      const amountList = row.original.amountList.join(', ')
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(amountList.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Price List ${amountList} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {amountList}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'spread',
    header: () => <div className='text-xs text-left text-white'>Spread</div>,
    cell: ({ row }) => {
      const spread = row.original.spread
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(spread.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Spread ${spread} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {spread}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'startingBaseAmount',
    header: () => <div className='text-xs text-left text-white'>Starting Base Amount</div>,
    cell: ({ row }) => {
      const startingBaseAmount = row.original.startingBaseAmount
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(startingBaseAmount.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Starting Base Amount ${startingBaseAmount} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {startingBaseAmount}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'status',
    header: () => <div className='text-xs text-left text-white'>Status</div>,
    cell: ({ row }) => {
      const status = 'FUTURE FEATURE' //row.original.status
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(status.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Status ${status} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {status}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'start',
    header: () => <div className='text-xs text-left text-white'>Start</div>,
    cell: ({ row, table }) => {
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            if (table?.options?.meta?.startStopOrderBot) {
              table?.options?.meta?.startStopOrderBot(row.original.botID, true)
            }
          }}
        >
          <IoPlaySharp size={18} className={'text-primary cursor-pointer'} />
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'stop',
    header: () => <div className='text-xs text-left text-white'>Stop</div>,
    cell: ({ row, table }) => {
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            if (table?.options?.meta?.startStopOrderBot) {
              table?.options?.meta?.startStopOrderBot(row.original.botID, false)
            }
          }}
        >
          <IoStopSharp size={18} className={'text-primary cursor-pointer'} />
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'edit',
    header: () => <div className='text-xs text-left text-white'>Edit</div>,
    cell: ({ row, table }) => {
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            if (table?.options?.meta?.editOrderBot) {
              table?.options?.meta?.editOrderBot(row.original.botID)
            }
          }}
        >
          <FiEdit size={18} className={'text-primary cursor-pointer'} />
        </div>
      )
    },
    size: 50,
  },
]
