import { useCallback, useEffect, useMemo, useState } from 'react'
import { BotStrategy, getSdk, UpsertOrderBot } from '@/graphql/generated/graphql-request'
import { useOrderBots } from '@/hooks/useOrderBots'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { useOrderBooks } from '@/hooks/useOrderBooks'

interface CreateOrderBotModalProps {
  orderBotID?: string | null
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const CreateOrderBotModal = ({ orderBotID, isOpen, setIsOpen }: CreateOrderBotModalProps) => {
  const { orderBots } = useOrderBots()
  const { orderBooks } = useOrderBooks()
  const [name, setName] = useState('')
  const [orderBookID, setOrderBookID] = useState(orderBooks[0]?.orderBookID || '')
  const [botID, setBotID] = useState(orderBotID || '')
  const [amountList, setAmountList] = useState<string>('')
  const [baseDecimals, setBaseDecimals] = useState<number | undefined>(undefined)
  const [customTick, setCustomTick] = useState<number | undefined>(undefined)
  const [frequency, setFrequency] = useState<number | undefined>(undefined)
  const [levels, setLevels] = useState<number | undefined>(undefined)
  const [maxMult, setMaxMult] = useState<number | undefined>(undefined)
  const [minMult, setMinMult] = useState<number | undefined>(undefined)
  const [priceList, setPriceList] = useState<string>('')
  const [quoteDecimals, setQuoteDecimals] = useState<number | undefined>(undefined)
  const [spread, setSpread] = useState<number | undefined>(undefined)
  const [startingBaseAmount, setStartingBaseAmount] = useState<number | undefined>(undefined)
  const [strategy, setStrategy] = useState<BotStrategy>(BotStrategy.Dynamic)
  const orderBot = useMemo(() => orderBots.find((bot) => bot.botID === orderBotID), [orderBotID, orderBots])
  const title = useMemo(() => (orderBot ? 'Edit Order Bot' : 'Create Order Bot'), [orderBot])
  const buttonLabel = useMemo(() => (orderBot ? 'Update' : 'Create'), [orderBot])

  const handleClose = () => {
    setIsOpen(false)
  }

  const reset = () => {
    setName('')
    setOrderBookID('')
    setBotID('')
    setAmountList('')
    setBaseDecimals(undefined)
    setCustomTick(undefined)
    setFrequency(undefined)
    setLevels(undefined)
    setMaxMult(undefined)
    setMinMult(undefined)
    setPriceList('')
    setQuoteDecimals(undefined)
    setSpread(undefined)
    setStartingBaseAmount(undefined)
    setStrategy(BotStrategy.Dynamic)
  }

  useEffect(() => {
    if (isOpen && orderBot) {
      setName(orderBot.name)
      setOrderBookID(orderBot.orderBookID)
      setBotID(orderBot.botID)
      setAmountList(orderBot.amountList.join(','))
      setBaseDecimals(orderBot.baseDecimals)
      setCustomTick(orderBot.customTick)
      setFrequency(orderBot.frequency)
      setLevels(orderBot.levels)
      setMaxMult(orderBot.maxMult)
      setMinMult(orderBot.minMult)
      setPriceList(orderBot.priceList.join(','))
      setQuoteDecimals(orderBot.quoteDecimals)
      setSpread(orderBot.spread)
      setStartingBaseAmount(orderBot.startingBaseAmount)
      setStrategy(orderBot.strategy)
    } else if (!isOpen) {
      reset()
    }
  }, [isOpen, orderBot])

  const handleSubmit = useCallback(() => {
    const newOrderBot: UpsertOrderBot = {
      botID: orderBotID || botID,
      name,
      orderBookID,
      amountList: amountList.split(',').map(Number),
      baseDecimals: baseDecimals ?? 0,
      customTick: customTick ?? 0,
      frequency: frequency ?? 0,
      levels: levels ?? 0,
      maxMult: maxMult ?? 0,
      minMult: minMult ?? 0,
      priceList: priceList.split(',').map(Number),
      quoteDecimals: quoteDecimals ?? 0,
      spread: spread ?? 0,
      startingBaseAmount: startingBaseAmount ?? 0,
      strategy,
    }
    console.log('Order Bot mutation:', newOrderBot)
    upsertOrderBotMutation.mutate(newOrderBot)
  }, [
    orderBotID,
    name,
    orderBookID,
    amountList,
    baseDecimals,
    customTick,
    frequency,
    levels,
    maxMult,
    minMult,
    priceList,
    quoteDecimals,
    spread,
    startingBaseAmount,
    strategy,
    handleClose,
  ])

  const upsertOrderBotMutation = useMutation({
    mutationFn: (newOrderBot: UpsertOrderBot) =>
      getSdk(graphQLClient)
        .UpsertOrderBot({ bot: newOrderBot })
        .then(() => {
          const message = orderBot ? 'Order Bot was updated successful' : 'Order Bot was created successful'
          createNotification('Order Bot', message, NotificationType.SIMPLE)
        })
        .catch((err) => {
          const message = err?.message ?? (orderBot ? 'Order Bot was not updated' : 'Order Bot was not created')
          createNotification('Order Bot', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ORDER_BOTS'] }).finally()
      handleClose()
    },
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  return (
    <div className={'z-40'}>
      {isOpen && (
        <div className={'relative'} onClick={handleClose}>
          <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
            <div
              id={'create-orderbot-modal'}
              className={
                'w-1/2 p-4 bg-panel flex flex-col justify-center items-center border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={handleClose} className={'absolute top-2 right-2 text-white text-xl'}>
                &times;
              </button>
              <div className={'text-primary text-xl font-semibold mb-6 '}>{title}</div>
              <div className={'grid grid-cols-4 gap-6 text-center items-center'}>
                <label className='text-white'>Name</label>
                <input
                  type='text'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Bot ID</label>
                <input
                  type='text'
                  placeholder='Bot ID'
                  value={botID}
                  disabled={!!orderBotID}
                  onChange={(e) => setBotID(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>OrderBook ID</label>
                <select
                  value={orderBookID}
                  onChange={(e) => setOrderBookID(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                >
                  {orderBooks.map((orderBook) => (
                    <option key={orderBook.orderBookID} value={orderBook.orderBookID}>
                      {orderBook.orderBookID}
                    </option>
                  ))}
                </select>
                <label className='text-white'>Amount List (comma separated)</label>
                <input
                  type='text'
                  placeholder='10, 50, 600....'
                  value={amountList}
                  onChange={(e) => setAmountList(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Base Decimals</label>
                <input
                  type='number'
                  placeholder='Base Decimals'
                  value={baseDecimals ?? ''}
                  onChange={(e) => setBaseDecimals(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Custom Tick</label>
                <input
                  type='number'
                  placeholder='Custom Tick'
                  value={customTick ?? ''}
                  onChange={(e) => setCustomTick(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Frequency</label>
                <input
                  type='number'
                  placeholder='Frequency'
                  value={frequency ?? ''}
                  onChange={(e) => setFrequency(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Levels</label>
                <input
                  type='number'
                  placeholder='Levels'
                  value={levels ?? ''}
                  onChange={(e) => setLevels(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Max Mult</label>
                <input
                  type='number'
                  placeholder='Max Mult'
                  value={maxMult ?? ''}
                  onChange={(e) => setMaxMult(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Min Mult</label>
                <input
                  type='number'
                  placeholder='Min Mult'
                  value={minMult ?? ''}
                  onChange={(e) => setMinMult(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Price List (comma separated)</label>
                <input
                  type='text'
                  placeholder='80,90,100...'
                  value={priceList}
                  onChange={(e) => setPriceList(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Quote Decimals</label>
                <input
                  type='number'
                  placeholder='Quote Decimals'
                  value={quoteDecimals ?? ''}
                  onChange={(e) => setQuoteDecimals(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Spread</label>
                <input
                  type='number'
                  placeholder='Spread'
                  value={spread ?? ''}
                  onChange={(e) => setSpread(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Starting Base Amount</label>
                <input
                  type='number'
                  placeholder='Starting Base Amount'
                  value={startingBaseAmount ?? ''}
                  onChange={(e) => setStartingBaseAmount(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Strategy</label>
                <select
                  value={strategy}
                  onChange={(e) => setStrategy(e.target.value as BotStrategy)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                >
                  <option value={BotStrategy.Dynamic}>Dynamic</option>
                  <option value={BotStrategy.Static}>Static</option>
                </select>
              </div>
              <button
                onClick={handleSubmit}
                className='mt-8 mb-4 btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
              >
                {buttonLabel}
              </button>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        onClick={() => setIsOpen(true)}
        className='btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
      >
        Create Order Bot
      </button>
    </div>
  )
}
