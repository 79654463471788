// DatafeedConfiguration implementation
import {
  HistoryCallback,
  LibrarySymbolInfo,
  ResolutionString,
  ResolveCallback,
  ErrorCallback,
  SearchSymbolsCallback,
  Timezone,
} from '@/tradingview/charting_library'
import { RESOLUTION_MAP, RESOLUTION_MAP_TV_GRAPHQL } from '@/utils/constants'
import { Asset, Candle, getSdk } from '@/graphql/generated/graphql-request'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
import tz from 'dayjs/plugin/timezone'
import { subscribeOnStream } from '@/hooks/tradingView/subscribeBars'
import { graphQLClient } from '@/services/graphql'
import { store } from '@/store'
import { setChartCandles, setLastChartPrice } from '@/store/chartPriceSlice'

dayjs.extend(utc)
dayjs.extend(tz)
const timezone = dayjs.tz.guess() as Timezone
console.log('configurationData:::::::::::::', Object.keys(RESOLUTION_MAP))
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  // supported_resolutions: Object.keys(RESOLUTION_MAP) as ResolutionString[],
  supported_resolutions: ['1', '15', '1D'] as ResolutionString[],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: 'Bitfinex', name: 'Bitfinex', desc: 'Bitfinex' },
    { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange' },
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: 'crypto', value: 'crypto' }],
}

export type ExtendedCandle = Candle & {
  isLastBar?: boolean
}

export interface TradingViewSymbol {
  description: string
  exchange: string
  full_name: string
  symbol: string
  type: string
}

export const getAllSymbols = (marketIds: string[]): TradingViewSymbol[] =>
  marketIds.map((marketId) => ({
    description: marketId,
    exchange: 'dora',
    full_name: marketId,
    symbol: marketId,
    type: 'crypto',
  }))

export const getSymbol = (
  marketId: string,
  leftAsset: Asset | undefined,
  rightAsset: Asset | undefined,
): TradingViewSymbol => ({
  description: leftAsset && rightAsset ? leftAsset?.symbol.concat('-', rightAsset?.symbol) : marketId,
  exchange: 'dora',
  full_name: leftAsset && rightAsset ? leftAsset?.symbol.concat('-', rightAsset?.symbol) : marketId,
  symbol: leftAsset && rightAsset ? leftAsset?.symbol.concat('-', rightAsset?.symbol) : marketId,
  type: 'crypto',
})
// Obtains all symbols for all exchanges supported by CryptoCompare API

export const getDatafeed = (
  initialPriceScale: number | null,
  tickSizeDecimals: number | null = 2,
  allAssets: Asset[],
  defaultOrderBookID: string,
) => ({
  onReady: (callback: FixMe) => {
    console.log('[onReady]: Method call')
    setTimeout(() => callback(configurationData))
  },

  searchSymbols: (
    userInput: string,
    exchange: string,
    symbolType: string,
    onResultReadyCallback: SearchSymbolsCallback,
  ) => {
    onResultReadyCallback([])
  },

  resolveSymbol: async (
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback,
    onResolveErrorCallback: ErrorCallback,
  ) => {
    const splitAssetIds = symbolName.split('-')
    const leftAsset = allAssets.find((asset) => asset.uid === splitAssetIds[0])
    const rightAsset = allAssets.find((asset) => asset.uid === splitAssetIds[1])

    const symbolItem = getSymbol(symbolName || defaultOrderBookID, leftAsset, rightAsset)
    // const { tickSizeDecimals } = getMarketConfig(symbolItem.symbol)(store.getState()) || {}
    // TODO: we need to find a way to fetch market pools configurations

    const pricescale = tickSizeDecimals ? 10 ** tickSizeDecimals : (initialPriceScale ?? 100)

    console.log('symbolItem:::::::::::::', symbolItem)
    console.log('timezone:::::::::::::', timezone)
    const symbolInfo: LibrarySymbolInfo = {
      data_status: 'streaming',
      description: symbolItem.description,
      exchange: 'dora',
      format: 'price',
      full_name: symbolItem.full_name,
      has_daily: true,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '30', '60', '240'],
      listed_exchange: 'dora',
      minmov: 1,
      name: symbolItem.symbol,
      pricescale,
      session: '24x7',
      supported_resolutions: configurationData.supported_resolutions as ResolutionString[],
      ticker: symbolName,
      timezone,
      type: symbolItem.type,
    }

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0)
  },

  getBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: {
      countBack: number
      from: number
      to: number
      firstDataRequest: boolean
    },
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback,
  ) => {
    setTimeout(async () => {
      console.log('getBars:::::::::::::', symbolInfo, resolution, periodParams)

      const { countBack, from, to, firstDataRequest } = periodParams
      let fromMs = from * 1000
      let toMs = to * 1000

      if (firstDataRequest && resolution === '1D') {
        toMs += 1
      }

      const [assetInUid, assetOutUid] = symbolInfo.ticker?.split('-') || []

      console.log('FROM:::::::::::', dayjs(fromMs).format('DD-HH:mm'), fromMs)
      console.log('TO:::::::::::::', dayjs(toMs).format('DD-HH:mm'), toMs)

      // diff between 2 dates
      console.log('DIFF:::::::::::::', dayjs(toMs).diff(dayjs(fromMs), 'minute'))
      const fetchedCandles = await getSdk(graphQLClient)
        .GetCandles({
          from: fromMs,
          to: toMs,
          resolution: RESOLUTION_MAP_TV_GRAPHQL[resolution],
          assetInUid,
          assetOutUid,
        })
        .then((result) => {
          store.dispatch(
            setChartCandles({
              candles: (result.getCandles as Candle[]) || [],
              marketId: symbolInfo.ticker as string,
              resolution: RESOLUTION_MAP_TV_GRAPHQL[resolution],
            }),
          )
          store.dispatch(
            setLastChartPrice({
              ticker: symbolInfo.ticker,
              price: result.getCandles[result.getCandles.length - 1]?.close,
            }),
          )
          return result.getCandles
        })
        .catch((error) => {
          console.log('Error fetching candles', error)
          return []
        })
      if (fetchedCandles.length === 0) {
        onHistoryCallback([], {
          noData: true,
        })
        return
      }
      let chartData = [...fetchedCandles]

      console.log('HAS DATA???????', chartData.length > 0)
      if (chartData.length > 0) {
        console.log('DATA LENGT :::::::::::', chartData.length)
        console.log('DATA :::::::::::::::::', chartData)
        console.log('COUNTBACK ::::::::::::', periodParams.countBack)
        for (let i = chartData.length - 1; i > -1; i--) {
          chartData[i] = {
            open: chartData[i].open,
            high: chartData[i].high,
            low: chartData[i].low,
            close: chartData[i].close,
            time: chartData[i].time,
            volume: chartData[i].volume,
            isLastBar: i === chartData.length - 1,
          } as ExtendedCandle
        }
      }
      onHistoryCallback([...chartData], {
        noData: false,
      })
    }, 0)
  },

  subscribeBars: (
    symbolInfo: FixMe,
    resolution: FixMe,
    onRealtimeCallback: FixMe,
    subscriberUID: FixMe,
    onResetCacheNeededCallback: FixMe,
  ) => {
    subscribeOnStream(symbolInfo, resolution, onRealtimeCallback)
  },
  unsubscribeBars: (subscriberUID: FixMe) => {
    console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID)
  },
})
